<template>
  <div>
    <header class="mb-12">
      <h1 class="mt-5">Prospect Import</h1>
      <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p>
    </header>

    <v-card outlined elevation="12" max-width="900" class="mx-auto">
      <v-card-title>Bulk Prospect Importing</v-card-title>
      <v-card-text
        >Upload a CSV file to import prospects.
        <strong>All fields are required.</strong></v-card-text
      >
      <v-divider></v-divider>
      <v-card-text>
        <h4 class="mb-4">Advisor Search</h4>
        <v-form ref="form" v-model="valid" class="mt-5">
          <SearchSelectAdvisor
            v-model="selectedAdvisor"
            :required="true"
            :multiple="false"
          />
          <h4 class="mb-4">File Upload</h4>
          <v-row class="" pa-3>
            <v-col cols="12">
              <vue-csv-import v-model="file" :map-fields="tableHeaders">
                <template slot="hasHeaders" slot-scope="{ headers, toggle }">
                  <p>
                    1. Select whether the CSV file to upload has a header row.
                  </p>
                  <v-checkbox
                    label="CSV file has a header row"
                    :value="headers"
                    @change="toggle"
                  ></v-checkbox>

                  <p>2. Choose a CSV file to import.</p>
                </template>

                <template slot="thead">
                  <tr>
                    <th
                      colspan="2"
                      style="border: none; padding-bottom: 1rem"
                      class="text-center text-uppercase"
                    >
                      Map Fields
                    </th>
                  </tr>
                  <tr>
                    <th>Prospect Fields</th>
                    <th>CSV Column</th>
                  </tr>
                </template>

                <template slot="next" slot-scope="{ load }">
                  <div v-if="fileName" class="my-3">
                    <strong>Chosen File:</strong> {{ fileName }}
                  </div>

                  <p>3. Map file columns to prospect fields.</p>
                  <v-btn
                    ref="mapFieldsButton"
                    type="button"
                    color="primary"
                    :disabled="!fileName"
                    @click="load"
                    >Map Fields</v-btn
                  >
                </template>
              </vue-csv-import>
            </v-col>

            <v-col v-if="file" cols="12" class="mt-4">
              <v-divider class="mb-8"></v-divider>
              <h3 class="mb-2 text-center">Mapping Results</h3>
              <p class="mb-4 text-center">
                Review mapping results and import prospects.
              </p>
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="header in tableHeaders"
                        :key="header"
                        class="text-left"
                      >
                        {{ header.toUpperCase() }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!file">
                      <td colspan="4" class="text-center grey-text">
                        No fields mapped
                      </td>
                    </tr>
                    <template v-if="file">
                      <tr
                        v-for="(item, index) in file"
                        :key="item.email + '-' + index"
                      >
                        <td>
                          <small>{{ item.email }}</small>
                        </td>
                        <td>
                          <small>{{ item.firstName }}</small>
                        </td>
                        <td>
                          <small>{{ item.lastName }}</small>
                        </td>
                        <td>
                          <small>{{ item.phone }}</small>
                        </td>
                        <td>
                          <small>{{ item.streetAddress1 }}</small>
                        </td>
                        <!-- <td>
                          <small>{{
                            item.streetAddress2 ? item.streetAddress2 : ""
                          }}</small>
                        </td> -->
                        <td>
                          <small>{{ item.city }}</small>
                        </td>
                        <td>
                          <small>{{ item.state }}</small>
                        </td>
                        <td>
                          <small>{{ item.postalCode }}</small>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-6 justify-center"
        ><v-btn text color="error" @click="handleReset" class="mr-4"
          >Clear</v-btn
        >
        <v-btn
          type="button"
          :disabled="!selectedAdvisor || !file"
          @click="showConfirmDialog = true"
          color="primary"
          >Import Prospects</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-dialog v-model="showSuccessDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text success">
          Importing has started
        </v-card-title>
        <v-card-text>
          <h4>An email will be sent when importing is complete.</h4>
          <h4>What would you like to do now?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            type="button"
            color="primary"
            @click.stop="handleDoMore()"
            class="mr-4"
          >
            Import More Prospects
          </v-btn>

          <v-btn color="secondary" to="/admin"> Go to Dashboard </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text error">
          There is a problem!
        </v-card-title>
        <v-card-text>
          <h4>An error has occurred.</h4>
          <p>
            <strong>{{ dialogErrorText }}</strong>
          </p>
          <p>Please refresh and try again.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="showErrorDialog = false"
            class="mr-4"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" persistent max-width="500">
      <v-card>
        <v-overlay
          :value="importLoading"
          color="#091437"
          opacity="0.85"
          absolute="absolute"
          class="text-center"
        >
          <h3 class="mb-4">Uploading prospects...</h3>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-card-title class="mb-4 text-h5 white--text warning">
          Are you sure?
        </v-card-title>

        <v-card-text>
          <p>
            Prospects will be imported and added to HubSpot for the selected
            advisor.
          </p>

          <p>Are you sure you want to import prospects?</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showConfirmDialog = false">
            Cancel
          </v-btn>

          <v-btn color="primary" text @click="handleConfirm"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, Auth } from "aws-amplify";
import SearchSelectAdvisor from "@/components/SearchSelectAdvisor";
import { VueCsvImport } from "vue-csv-import";
import { customGetAdvisorSimple } from "@/customGraphQL/customQueries";

export default {
  components: {
    SearchSelectAdvisor,
    VueCsvImport,
  },
  data() {
    return {
      search: null,
      advisors: [],
      selectedAdvisor: [],
      file: null,
      tableHeaders: [
        "email",
        "firstName",
        "lastName",
        "phone",
        "streetAddress1",
        // "Street Address 2",
        "city",
        "state",
        "postalCode",
      ],
      //  tableHeaders: [
      //   "Email",
      //   "First Name",
      //   "Last Name",
      //   "Phone",
      //   "Street Address",
      //   // "Street Address 2",
      //   "City",
      //   "State",
      //   "Zip Code",
      // ],
      fileInput: null,
      fileName: null,
      JWT: null,
      valid: true,
      loading: false,
      importLoading: false,
      showConfirmDialog: false,
      showSuccessDialog: false,
      showErrorDialog: false,
      dialogErrorText: "",
    };
  },

  created() {
    const advisorId = this.$route.query["advisor"];

    if (advisorId) {
      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const regexValid = regex.test(advisorId);

      if (!regexValid) {
        console.log("Invalid Advisor ID query string param.");

        return;
      } else {
        this.getAdvisorSimple(advisorId);
      }
    }
  },
  mounted() {
    Auth.currentSession().then((data) => {
      this.JWT = data.getAccessToken().getJwtToken();
    });

    this.fileInput = this.$el.querySelector(".form-control-file");
    const mapFieldsBox = this.$el.querySelector(".vue-csv-uploader-part-two");
    mapFieldsBox.classList.add("is-empty");
    let p = document.createElement("p");
    p.innerHTML = "No fields to map";
    mapFieldsBox.append(p);

    this.fileInput.addEventListener("change", (e) => {
      this.fileName = e.target.files[0].name;
    });

    this.$refs.mapFieldsButton.$el.addEventListener("click", () => {
      mapFieldsBox.classList.remove("is-empty");
    });
  },
  methods: {
    // async handleUploadFile(e) {
    //   const file = e;
    //   try {
    //     const resp = await Storage.put(
    //       "bulk-prospect-imports/" + file.name,
    //       file,
    //       {
    //         level: "private",
    //         bucket: awsExports.aws_user_files_s3_bucket,
    //         contentType: "csv",
    //       }
    //     );
    //     console.log("resp", resp);
    //   } catch (error) {
    //     console.log("Error uploading file: ", error);
    //   }
    // },
    async getAdvisorSimple(advisorId) {
      try {
        const res = await API.graphql({
          query: customGetAdvisorSimple,
          variables: { id: advisorId },
          authMode: this.authMode,
        });

        // return res.data.getAdvisor;
        this.selectedAdvisor = {
          id: res.data.getAdvisor.id,
          name: `${res.data.getAdvisor.lastName}, ${res.data.getAdvisor.firstName}`,
        };
      } catch (error) {
        console.log("Error getting advisor simple.", error);
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      }
    },
    async handleConfirm() {
      this.importLoading = true;

      const data = {
        advisorId: this.selectedAdvisor.id,
        prospects: this.file,
      };
      console.log("IMPORT PROSPECT", data);

      try {
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "import_prospects",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.JWT}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const results = await response.json();
          console.log("RESULTS", results);
          if (results.success) {
            setTimeout(() => {
              this.importLoading = false;
              this.showConfirmDialog = false;
              this.showSuccessDialog = true;
            }, 1000);
          } else {
            throw new Error(
              "Something went wrong importing prospects.",
              results.message
            );
          }
        } else {
          throw new Error(
            "Something went wrong importing prospects.",
            response.status
          );
        }
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        this.showConfirmDialog = false;
        this.showSuccessDialog = false;
        console.log("Error calling api to import prospects", error);
      } finally {
        // this.handleReset();
      }
    },
    handleDoMore() {
      this.$router.go(0);
      this.showSuccessDialog = false;
    },
    handleReset() {
      this.$router.go(0);
      this.showSuccessDialog = false;
      // console.log("here in reset");
      // this.$refs.form.reset();
      // this.$nextTick(() => {
      //   this.search = null;
      //   this.advisors = [];
      //   this.file = null;
      //   this.fileInput = null;
      //   this.fileName = null;
      //   this.selectedAdvisor = null;
      // });
    },
  },
};
</script>

<style lang="scss">
.vue-csv-uploader {
  .form {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    > div {
      flex: 1;
      margin-top: 2rem;
      @media only screen and (min-width: 768px) {
        margin-top: 0;
      }
    }
  }

  .invalid-feedback {
    margin: 0.5rem 0;
    color: var(--v-error-base) !important;
  }

  table {
    width: 100%;
    margin-top: 0.5rem;

    th {
      text-align: left;
      border-bottom: 1px solid;
    }

    td {
      padding-top: 0.5rem;
    }
  }

  select {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    width: 100%;
    color: black;
    padding: 6px;
  }

  .vue-csv-uploader-part-two {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 0.5rem;

    p {
      display: none;
    }

    &.is-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.02);

      p {
        display: block;
      }
    }
  }

  .csv-import-file {
    margin-bottom: 1rem;
  }

  .form-control-file {
    color: transparent;
  }
  .form-control-file::-webkit-file-upload-button {
    visibility: hidden;
  }
  .form-control-file::before {
    content: "Choose CSV File";
    min-width: 64px;
    color: white;
    display: inline-block;
    background-color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
    border-radius: 4px;
    padding: 6px 16px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-transform: uppercase;

    font-size: 0.875rem;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 8%),
      0px 2px 2px 0px rgb(0 0 0 / 5%), 0px 1px 5px 0px rgb(0 0 0 / 4%);
  }
  .form-control-file:hover::before {
    border-color: black;
  }
  .form-control-file:active {
    outline: 0;
  }
  .form-control-file:active::before {
    opacity: 0.7;
  }
}
</style>
